import {
  REFERRER_HOSTS,
  SESSION_KEY,
  UTM_TAGS_DEFAULT_VALUES,
  UTM_TAGS_LIST,
  UTM_TAGS_MAP,
} from './get-constants.js';

export function getUtmParams(params) {
  return {
    [UTM_TAGS_MAP.utm_source]: params.get(UTM_TAGS_MAP.utm_source),
    [UTM_TAGS_MAP.utm_medium]: params.get(UTM_TAGS_MAP.utm_medium),
    [UTM_TAGS_MAP.utm_campaign]: params.get(UTM_TAGS_MAP.utm_campaign),
    [UTM_TAGS_MAP.utm_term]: params.get(UTM_TAGS_MAP.utm_term),
    [UTM_TAGS_MAP.utm_content]: params.get(UTM_TAGS_MAP.utm_content),
  };
}

export function hasAnyUtmParams(params) {
  const utmPrams = getUtmParams(params);

  return UTM_TAGS_LIST.some(tag => utmPrams[tag]);
}

export function hasReferrer() {
  const currentHost = window.location.host;
  const currentReferrerHost = document.referrer ? new URL(document.referrer).host : '';

  if (!document.referrer || location.href.includes(document.referrer) || currentHost === currentReferrerHost) {
    return false;
  }

  console.log('Referrer', document.referrer);

  return true;
}

export function getUtmParamsWithDefault(params) {
  return {
    [UTM_TAGS_MAP.utm_source]:
      params.get(UTM_TAGS_MAP.utm_source) ||
      UTM_TAGS_DEFAULT_VALUES[UTM_TAGS_MAP.utm_source],
    [UTM_TAGS_MAP.utm_medium]:
      params.get(UTM_TAGS_MAP.utm_medium) ||
      UTM_TAGS_DEFAULT_VALUES[UTM_TAGS_MAP.utm_medium],
    [UTM_TAGS_MAP.utm_campaign]:
      params.get(UTM_TAGS_MAP.utm_campaign) ||
      UTM_TAGS_DEFAULT_VALUES[UTM_TAGS_MAP.utm_campaign],
    [UTM_TAGS_MAP.utm_term]:
      params.get(UTM_TAGS_MAP.utm_term) ||
      UTM_TAGS_DEFAULT_VALUES[UTM_TAGS_MAP.utm_term],
    [UTM_TAGS_MAP.utm_content]:
      params.get(UTM_TAGS_MAP.utm_content) ||
      UTM_TAGS_DEFAULT_VALUES[UTM_TAGS_MAP.utm_content],
  };
}

export function sessionExpiredChecker() {
  const session = JSON.parse(localStorage.getItem(SESSION_KEY));

  console.log({ session });

  if (!session) {
    return true;
  }

  const expirationDate = session.expirationDate;
  const currentDate = new Date().getTime();

  return currentDate > expirationDate;
}

export function getUtmParamsFromSession() {
  const session = JSON.parse(localStorage.getItem(SESSION_KEY));

  if (!session) {
    return {};
  }

  return session.utm;
}
