import {
  REFERRER_HOSTS,
  SEARCH_ENGINE_HOSTS,
  SESSION_KEY,
  SOCIAL_MEDIA_HOSTS,
  UTM_TAGS_MAP,
  UTM_TAGS_REFERRER_DEFAULT_VALUES,
} from './get-constants.js';
import {
  getUtmParams,
  getUtmParamsWithDefault,
  hasAnyUtmParams,
  hasReferrer,
  sessionExpiredChecker,
} from './helpers.js';

export function initializeSession({ sessionExpirationTime }) {
  const params = new URLSearchParams(window.location.search);

  if (hasAnyUtmParams(params)) {
    console.info('Found UTM params: Creating session with UTM params');
    createSessionWithUtmParams({ params, sessionExpirationTime });
  } else if (hasReferrer()) {
    console.info('Found know referrer: Creating new session with referrer');
    createSessionWithReferral({ sessionExpirationTime });
  } else if (sessionExpiredChecker()) {
    console.info('Session Expired: Creating new session with default values');
    createSession({
      utmParams: getUtmParamsWithDefault(params),
      sessionExpirationTime,
    });
  }
}

export function createSession({ utmParams, sessionExpirationTime }) {
  const expirationDate = new Date().getTime() + sessionExpirationTime * 60000; // multiply by 60000 to get time in milliseconds

  const session = {
    utm: utmParams,
    expirationDate,
  };

  localStorage.setItem(SESSION_KEY, JSON.stringify(session));
}

export function createSessionWithUtmParams({ params, sessionExpirationTime }) {
  const utmParams = getUtmParams(params);
  createSession({ utmParams, sessionExpirationTime });
}

export function createSessionWithReferral({
  referrer = '',
  sessionExpirationTime,
}) {
  const currentHost = window.location.host;
  const currentReferrerHost = referrer || new URL(document.referrer).host;
  let utm_source = currentHost === currentReferrerHost ? 'Direct' : currentReferrerHost;
  let utm_medium, utm_campaign, utm_term, utm_content;

  if (REFERRER_HOSTS.includes(utm_source)) {
    if (SOCIAL_MEDIA_HOSTS.includes(utm_source)) {
      utm_medium = 'Organic Social';
    } else if (SEARCH_ENGINE_HOSTS.includes(utm_source)) {
      utm_medium = 'Organic Search';
    }

    utm_source = utm_source.replace('.com', '').replace('www.', '');
  } else {
    utm_medium = 'Web Referral';
  }

  const utmParams = {
    [UTM_TAGS_MAP.utm_source]:
      utm_source || UTM_TAGS_REFERRER_DEFAULT_VALUES[UTM_TAGS_MAP.utm_source],
    [UTM_TAGS_MAP.utm_medium]:
      utm_medium || UTM_TAGS_REFERRER_DEFAULT_VALUES[UTM_TAGS_MAP.utm_medium],
    [UTM_TAGS_MAP.utm_campaign]:
      utm_campaign ||
      UTM_TAGS_REFERRER_DEFAULT_VALUES[UTM_TAGS_MAP.utm_campaign],
    [UTM_TAGS_MAP.utm_term]:
      utm_term || UTM_TAGS_REFERRER_DEFAULT_VALUES[UTM_TAGS_MAP.utm_term],
    [UTM_TAGS_MAP.utm_content]:
      utm_content || UTM_TAGS_REFERRER_DEFAULT_VALUES[UTM_TAGS_MAP.utm_content],
  };

  createSession({ utmParams, sessionExpirationTime });
}
