import { initializeSession } from './session.js';
import { checkFields } from './check-fields.js';

const UtmTags = (() => {
  let initParams = {
    sessionExpirationTime: 30, // 30 minutes
    inputSelectType: 'name',
    container: '',
    customFormFields: {},
  };
  let initialized = false;

  // NOTE: Run in async way to make sure it doesn't block the page load
  async function init(customParams) {
    initParams = { ...initParams, ...customParams };
    const {
      sessionExpirationTime,
      inputSelectType,
      customFormFields,
      container,
    } = initParams;

    initializeSession({ sessionExpirationTime });
    const checkFieldsParams = {
      inputSelectType,
      customFormFields,
      container,
    };

    if (initialized) {
      checkFields(checkFieldsParams);
    } else {
      initialized = true;
      window.addEventListener('load', () => checkFields(checkFieldsParams));
    }
  }

  // utm params query example ?utm_source=google&utm_medium=cpc&utm_campaign=brand&utm_term=keyword&utm_content=ad
  return {
    init,
    customInit: customParams => {
      if (!customParams?.container) {
        throw new Error('container param is required');
      }

      init(customParams || initParams);
    },
  };
})();

if (!window.UtmTags) {
  window.UtmTags = UtmTags;
}
